import {useQuery} from '@tanstack/react-query'
import * as chatsAPI from '../index';
import get from 'lodash/get';

const queryKey = (token, params) => [token, 'unread-messages-count', params];

export const useUnreadMessagesCount = (
    token,
    options,
) => {
  const params = {
    expand: get(options, 'expand')
  };

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKey(token, params),
    () => new Promise((resolve, reject) => {
      chatsAPI.unreadCounts({token, params})
        .then(res => resolve(res.data.count))
        .catch(reject);
    }),
    {
      refetchOnMount: get(options, 'refetchOnMount'),
      refetchOnReconnect: get(options, 'refetchOnReconnect'),
      refetchOnWindowFocus: get(options, 'refetchOnWindowFocus'),
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    refetch
  }
}