import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
} from 'reactstrap';
import FormInput from '../../components/FormInput';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import { login, resetLoginError } from '../../redux/auth/actions';
import loginLogo from '../../assets/img/brand/viewber_logo.png';
import loginSide from '../../assets/img/brand/login-side.webp';

const {
          REACT_APP_API_KEY,
          REACT_APP_WEBSITE_HOME,
      } = process.env;

export class Login extends Component {
    state = {
        email:    '',
        password: '',
    };
    
    constructor(props) {
        super(props);
        
        this.handleLogin = this.handleLogin.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }
    
    handleLogin(evt) {
        evt.preventDefault();
        
        const { resetLoginError, errors, login } = this.props;
        const { email, password } = this.state;
        
        const data = {
            api_key: REACT_APP_API_KEY,
            email,
            password,
        };
        
        if (errors.length > 0) {
            resetLoginError();
        }
        
        login({ data });
    };
    
    updateInputValue(evt) {
        const { resetLoginError, errors } = this.props;
        
        if (errors.length > 0) {
            resetLoginError();
        }
        
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }
    
    render() {
        const {
                  errors,
                  logged_in,
                  logged_out,
                  logging_in,
                  must_complete_mfa,
              } = this.props;
        
        if (must_complete_mfa === true) {
            return <Redirect to='/2fa' />;
        }
        
        if (logged_in === true) {
            return <Redirect to='/' />;
        }
        
        return (
            <div className='app flex-row align-items-center'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md='8'>
                            <Card className='p-2'
                                style={{ borderRadius: '0.75rem' }}>
                                <CardBody className='d-flex flex-xl-row flex-column-reverse align-items-center'>
                                    <Col className='col-12 col-xl-6'>
                                        <img src={loginLogo}
                                            alt='Viewber logo'
                                            className='w-75' />
                                        <h3 className='pt-4 pb-3'>Sign In as a Member</h3>
                                        <p className='hover-blue font-weight-bold font-sm pb-2'>
                                            Welcome back! Please enter your details...
                                        </p>
                                        <ApiErrorAlert errors={errors} />
                                        <Form innerRef={'login-form'}>
                                            <InputGroup className='mb-3'>
                                                <FormInput className='border-dark'
                                                    type='email'
                                                    name='email'
                                                    placeholder='Enter your email'
                                                    onChange={this.updateInputValue} />
                                            </InputGroup>
                                            <InputGroup className='mb-4'>
                                                <FormInput className='border-dark'
                                                    type='password'
                                                    name='password'
                                                    placeholder='Enter your password'
                                                    onChange={this.updateInputValue} />
                                            </InputGroup>
                                            <Button type='submit'
                                                color='auth-blue'
                                                disabled={logging_in}
                                                onClick={this.handleLogin}
                                                block>
                                                Login
                                            </Button>
                                            <Row className='mt-3'>
                                                <Col className='col-12 col-sm-6 pr-sm-1'>
                                                    <Button color='auth-light-blue'
                                                        tag='a'
                                                        href={`/password/reset`}
                                                        block>
                                                        Forgot password?
                                                    </Button>
                                                </Col>
                                                <Col className='col-12 col-sm-6 pl-sm-1 mt-3 mt-sm-0'>
                                                    
                                                    <Button color='ghost'
                                                        tag='a'
                                                        href={`${REACT_APP_WEBSITE_HOME}/viewing-agents`}
                                                        block>
                                                        Sign up
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col className='col-12 col-xl-6 d-none d-xl-block h-100'>
                                        <img src={loginSide}
                                            alt='Viewber Sign In'
                                            className='img-fluid' />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
              error,
              logged_out,
              logging_in,
              token,
              must_complete_mfa,
          } = state.auth;
    const errors = getApiErrorMessages(error);
    
    return {
        errors,
        must_complete_mfa,
        logged_in:  token ? true : false,
        logged_out: logged_out === true,
        logging_in: logging_in === true,
    };
};

const mapDispatchToProps = {
    login,
    resetLoginError,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
